import React from "react";
import { Link } from "react-router-dom";


import './footer.css';


export default function Footer(){


    const currenYear = new Date().getFullYear();

    return(
        <footer>
        <div className="footer-top-area footer-bg">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-3">
                        <div className="footer-widget">
                            <div className="f-logo">
                                <Link to={"/"}><img src={require("../img/logo/Logob.png")} alt=""/></Link>
                            </div>
                            <div className="footer-text">
                                <p style={{textAlign: "center"}}>The best overall Dog Breeder.</p>
                            </div>
                            <div className="footer-contact">
                                <div className="icon"><i className="fas fa-headphones"></i></div>
                                <div className="content">
                                    <h4 className="title"><a href="tel:7862823620">786-282-3620</a></h4>
                                    <span>Call Now</span>
                                </div>
                            </div>
                            <div className="footer-social">
                                <ul>
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://instagram.com/caballerogsbreeder?igshid=YmMyMTA2M2Y=" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="footer-widget">
                            <h4 className="fw-title">Our Policies</h4>
                            <div className="fw-link">
                                <ul>
                                    <li><a href="/">Privacy Policy</a></li>
                                    <li><a href="/">Terms and Conditions</a></li>
                                    <li><a href="/">Editorial Policy</a></li>
                                    <li><a href="/">Return Policy</a></li>
                                    <li><a href="/">IP Policy</a></li>
                                    <li><a href="/">Grievance Redressal Policy</a></li>
                                    <li><a href="/">Our Conditions</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="footer-widget">
                            <h4 className="fw-title">Our Services</h4>
                            <div className="fw-link">
                                <ul>
                                    <li><Link to={"/our-breeder"}>Breeder</Link></li>
                                    <li><Link to={"/adoption"}>Adoption</Link></li>
                                    <li><Link to={"/working-line"}>Working Line</Link></li>
                                    <li><Link to={"/show-line"}>Show Line</Link></li>
                                    <li><Link to={"/available-puppies"}>Available Puppies</Link></li>
                                    <li><Link to={"/contact-us"}>Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="footer-widget">
                            <h4 className="fw-title">Instagram</h4>
                            <div className="fw-insta">
                                <ul>
                                    <li><a href="#"><img src={require("../img/images/insta_img01.png")} alt=""/></a></li>
                                    <li><a href="#"><img src={require("../img/images/insta_img02.png")} alt=""/></a></li>
                                    <li><a href="#"><img src={require("../img/images/insta_img03.png")} alt=""/></a></li>
                                    <li><a href="#"><img src={require("../img/images/insta_img04.png")} alt=""/></a></li>
                                    <li><a href="#"><img src={require("../img/images/insta_img05.png")} alt=""/></a></li>
                                    <li><a href="#"><img src={require("../img/images/insta_img06.png")} alt=""/></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-shape"><img src={require("../img/images/footer_shape01.png")} alt=""/></div>
            <div className="footer-shape shape-two"><img src={require("../img/images/footer_shape02.png")} alt=""/></div>
        </div>
        <div className="copyright-area">
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <div className="copyright-text">
                            <p>Copyright &copy; {currenYear} D'CaballeroGSBreeder. All Rights Reserved.</p>
                        </div>
                    </div>
                    <div className="col-md-4 d-none d-md-block" style={{visibility: "hidden"}}>
                        <div className="footer-lang">
                            <div className="dropdown">
                                <button className="dropdown-toggle" type="button" id="dropdownMenuButton2" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <img src={require("../img/icon/united-states.png")} alt=""/> English
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                    <a className="dropdown-item" href="index.html"><img src={require("../img/icon/russia.png")} alt=""/>Russia</a>
                                    <a className="dropdown-item" href="index.html"><img src={require("../img/icon//thailand.png")} alt=""/>Thailand</a>
                                    <a className="dropdown-item" href="index.html"><img src={require("../img/icon/india.png")} alt=""/>India</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    );
}