import { create } from "zustand";
import { useEffect } from "react";

import stud1 from "../img/studs/stud1.jpeg";

const currenYear = new Date().getFullYear();






const store = create((set, get) => ({

    data: {},

    fetch: () => {
        fetch(`http://localhost:1337/api/puppies`)
          .then(res => res.json())
          .then(
            (result) => {
              //console.log(result)
              set({data: result})
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                console.log(error)
            }
          )
      },
    work_line_puppies: [
        {
            microship: 12345,
            breed: "Dobberman",
            birthYear: 2021,
            gender: 'M',
            age:  currenYear,
            color: "White",
            size: "26 lbs",
            city: "Miami",
            line: "work-line",
            picUrl: ""
        },
        {
            microship: 56789,
            breed: "Pastor Aleman",
            birthYear: 2021,
            gender: 'M',
            age:  currenYear,
            color: "White",
            size: "26 lbs",
            city: "Miami",
            line: "work-line",
            picUrl: "https://i1.wp.com/germanshepherddoghq.com/wp-content/uploads/2018/11/shutterstock_292897205.jpg?fit=1700%2C1400&ssl=1"
        },
        {
            microship: 768976,
            breed: "Pastor Aleman",
            birthYear: 2021,
            gender: 'M',
            age:  currenYear,
            color: "White",
            size: "26 lbs",
            city: "Miami",
            line: "work-line",
            picUrl: "https://cdn-0.germanshepherdcountry.com/wp-content/uploads/2020/12/1_sable-gsd_1000x666-min.jpg"
        },
        {
            microship: 989009,
            breed: "Pastor Aleman Negro",
            birthYear: 2021,
            gender: 'M',
            age:  currenYear,
            color: "Black",
            size: "26 lbs",
            city: "Miami",
            line: "work-line",
            picUrl: "https://northmountainkennels.com/wp-content/uploads/2021/07/bizz-litter-2.jpg.webp"
        },
        {
            microship: 789787,
            breed: "Pastor Aleman",
            birthYear: 2021,
            gender: 'M',
            age:  currenYear,
            color: "White",
            size: "26 lbs",
            city: "Miami",
            line: "work-line",
            picUrl: "https://i.ytimg.com/vi/QQr9PknQ1I8/maxresdefault.jpg"
        },
        {
            microship: 12345,
            breed: "Dobberman",
            birthYear: 2021,
            gender: 'F',
            age:  currenYear,
            color: "White",
            size: "26 lbs",
            city: "Miami",
            line: "work-line",
            picUrl: "https://images.squarespace-cdn.com/content/v1/54e7a1a6e4b08db9da801ded/1bd563c1-7bbb-4b44-af21-a60408b6f129/44.png?format=1000w"
        },
        {
            microship: 32456,
            breed: "Sable German Shepperd",
            birthYear: 2021,
            gender: 'F',
            age:  currenYear,
            color: "Silver",
            size: "26 lbs",
            city: "Miami",
            line: "work-line",
            picUrl: "https://puppyintraining.com/wp-content/uploads/Sable-German-Shepherd.jpg"
        },
        {
            microship: 56789,
            breed: "Pastor Aleman",
            birthYear: 2021,
            gender: 'F',
            age:  currenYear,
            color: "White",
            size: "26 lbs",
            city: "Miami",
            line: "work-line",
            picUrl: "https://i1.wp.com/germanshepherddoghq.com/wp-content/uploads/2018/11/shutterstock_292897205.jpg?fit=1700%2C1400&ssl=1"
        },
        {
            microship: 768976,
            breed: "Pastor Aleman",
            birthYear: 2021,
            gender: 'F',
            age:  currenYear,
            color: "White",
            size: "26 lbs",
            city: "Miami",
            line: "work-line",
            picUrl: "https://cdn-0.germanshepherdcountry.com/wp-content/uploads/2020/12/1_sable-gsd_1000x666-min.jpg"
        },
        {
            microship: 989009,
            breed: "Pastor Aleman Negro",
            birthYear: 2021,
            gender: 'F',
            age:  currenYear,
            color: "Black",
            size: "26 lbs",
            city: "Miami",
            line: "work-line",
            picUrl: "https://northmountainkennels.com/wp-content/uploads/2021/07/bizz-litter-2.jpg.webp"
        },
        {
            microship: 789787,
            breed: "Pastor Aleman",
            birthYear: 2021,
            gender: 'F',
            age:  currenYear,
            color: "White",
            size: "26 lbs",
            city: "Miami",
            line: "work-line",
            picUrl: "https://i.ytimg.com/vi/QQr9PknQ1I8/maxresdefault.jpg"
        }
    ],
    show_line_puppies: [
        {
            microship: 12345,
            breed: "Dobberman",
            birthYear: 2021,
            gender: 'M',
            age:  currenYear,
            color: "White",
            size: "26 lbs",
            city: "Miami",
            line: "show-line",
            picUrl: "https://images.squarespace-cdn.com/content/v1/54e7a1a6e4b08db9da801ded/1bd563c1-7bbb-4b44-af21-a60408b6f129/44.png?format=1000w"
        },
        {
            microship: 32456,
            breed: "Sable German Shepperd",
            birthYear: 2021,
            gender: 'M',
            age:  currenYear,
            color: "Silver",
            size: "26 lbs",
            city: "Miami",
            line: "show-line",
            picUrl: "https://puppyintraining.com/wp-content/uploads/Sable-German-Shepherd.jpg"
        },
        {
            microship: 56789,
            breed: "Pastor Aleman",
            birthYear: 2021,
            gender: 'M',
            age:  currenYear,
            color: "White",
            size: "26 lbs",
            city: "Miami",
            line: "show-line",
            picUrl: "https://i1.wp.com/germanshepherddoghq.com/wp-content/uploads/2018/11/shutterstock_292897205.jpg?fit=1700%2C1400&ssl=1"
        },
        {
            microship: 768976,
            breed: "Pastor Aleman",
            birthYear: 2021,
            gender: 'M',
            age:  currenYear,
            color: "White",
            size: "26 lbs",
            city: "Miami",
            line: "show-line",
            picUrl: "https://cdn-0.germanshepherdcountry.com/wp-content/uploads/2020/12/1_sable-gsd_1000x666-min.jpg"
        },
        {
            microship: 989009,
            breed: "Pastor Aleman Negro",
            birthYear: 2021,
            gender: 'M',
            age:  currenYear,
            color: "Black",
            size: "26 lbs",
            city: "Miami",
            line: "show-line",
            picUrl: "https://northmountainkennels.com/wp-content/uploads/2021/07/bizz-litter-2.jpg.webp"
        },
        {
            microship: 789787,
            breed: "Pastor Aleman",
            birthYear: 2021,
            gender: 'M',
            age:  currenYear,
            color: "White",
            size: "26 lbs",
            city: "Miami",
            line: "show-line",
            picUrl: "https://i.ytimg.com/vi/QQr9PknQ1I8/maxresdefault.jpg"
        },
        {
            microship: 989009,
            breed: "Pastor Aleman Negro",
            birthYear: 2021,
            gender: 'F',
            age:  currenYear,
            color: "Black",
            size: "26 lbs",
            city: "Miami",
            line: "work-line",
            picUrl: "https://northmountainkennels.com/wp-content/uploads/2021/07/bizz-litter-2.jpg.webp"
        },
        {
            microship: 12345,
            breed: "Dobberman",
            birthYear: 2021,
            gender: 'F',
            age:  currenYear,
            color: "White",
            size: "26 lbs",
            city: "Miami",
            line: "work-line",
            picUrl: "https://images.squarespace-cdn.com/content/v1/54e7a1a6e4b08db9da801ded/1bd563c1-7bbb-4b44-af21-a60408b6f129/44.png?format=1000w"
        },
        {
            microship: 32456,
            breed: "Sable German Shepperd",
            birthYear: 2021,
            gender: 'F',
            age:  currenYear,
            color: "Silver",
            size: "26 lbs",
            city: "Miami",
            line: "work-line",
            picUrl: "https://puppyintraining.com/wp-content/uploads/Sable-German-Shepherd.jpg"
        },
        {
            microship: 56789,
            breed: "Pastor Aleman",
            birthYear: 2021,
            gender: 'F',
            age:  currenYear,
            color: "White",
            size: "26 lbs",
            city: "Miami",
            line: "work-line",
            picUrl: "https://i1.wp.com/germanshepherddoghq.com/wp-content/uploads/2018/11/shutterstock_292897205.jpg?fit=1700%2C1400&ssl=1"
        },
        {
            microship: 768976,
            breed: "Pastor Aleman",
            birthYear: 2021,
            gender: 'F',
            age:  currenYear,
            color: "White",
            size: "26 lbs",
            city: "Miami",
            line: "work-line",
            picUrl: "https://cdn-0.germanshepherdcountry.com/wp-content/uploads/2020/12/1_sable-gsd_1000x666-min.jpg"
        },

        {
            microship: 789787,
            breed: "Pastor Aleman",
            birthYear: 2021,
            gender: 'F',
            age:  currenYear,
            color: "White",
            size: "26 lbs",
            city: "Miami",
            line: "work-line",
            picUrl: "https://i.ytimg.com/vi/QQr9PknQ1I8/maxresdefault.jpg"
        }
    ],

    studs: [
        {
            name: "Padre de Max",
            microship: 789787,
            breed: "Pastor Aleman",
            birthYear: 2021,
            gender: 'M',
            age:  currenYear,
            color: "White",
            size: "26 lbs",
            city: "Miami",
            line: "studs",
            picUrl: stud1
        }
    ]
}))


export default store;