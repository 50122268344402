import React from "react";
import Slider from "react-slick";

import { Link } from "react-router-dom";


import Wall2 from "../../img/Wall3.jpg";
import Wall from "../../img/Wall.jpg";

import './section_1.css';

export default function Section_1(){

    const options = {
        autoplay: false,
		autoplaySpeed: 10000,
		dots: false,
		arrows: true,
        responsive: [
			{ breakpoint: 767 }
		]
    }

    return(
        <section className="slider-area">
            <Slider className="slider-active">
                <div className="single-slider slider-bg d-flex align-items-center first-carrousel">
                <div style={{backgroundColor: "black", width: "100%", height: "100%", position: "fixed", opacity: "0.4"}}></div>
                    <div className="container custom-container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-7 col-md-10">
                                <div className="slider-content">
                                    <div className="slider-title">
                                        <h2 className="title" data-animation="fadeInUpBig" data-delay=".2s" data-duration="1.2s">Meet Our<span>Furry</span> Family</h2>
                                    </div>
                                    <div className="slider-desc">
                                        <p className="desc" data-animation="fadeInUpBig" data-delay=".4s" data-duration="1.2s">Comprehensive breeding, puppy sales, and adoption services,<br></br>
                                        to provide healthy and well-tempered puppies with loving homes.</p>
                                    </div>
                                    <Link to={"/available-puppies"} className="btn" data-animation="fadeInUpBig" data-delay=".6s" data-duration="1.2s">View More <img src={require("../../img/icon/w_pawprint.png")} alt=""/></Link>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="slider-shape"><img src={require("../../img/slider/slider_shape01.png")} alt=""/></div>
                    <div className="slider-shape shape-two"><img src={require("../../img/slider/slider_shape02.png")} alt=""/></div>
                </div>
                
                <div className="single-slider slider-bg d-flex align-items-center second-carrousel">
                <div style={{backgroundColor: "black", width: "100%", height: "100%", position: "fixed", opacity: "0.4"}}></div>
                    <div className="container custom-container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-7 col-md-10">
                                <div className="slider-content">
                                    <div className="slider-title">
                                        <h2 className="title" data-animation="fadeInUpBig" data-delay=".2s" data-duration="1.2s">Best Friend <span>with</span> Happy Time</h2>
                                    </div>
                                    <div className="slider-desc">
                                        <p className="desc" data-animation="fadeInUpBig" data-delay=".4s" data-duration="1.2s">Human Shampoo on Dogs After six days of delirat, the jury found Hernandez guilty of first-degree murder</p>
                                    </div>
                                    <Link to={"/available-puppies"} className="btn" data-animation="fadeInUpBig" data-delay=".6s" data-duration="1.2s">View More <img src={require("../../img/icon/w_pawprint.png")} alt=""/></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider-shape"><img src={require("../../img/slider/slider_shape01.png")} alt=""/></div>
                    <div className="slider-shape shape-two"><img src={require("../../img/slider/slider_shape02.png")} alt=""/></div>
                </div>
            </Slider>

        </section>
    );
}