import React from "react";
import { Link } from "react-router-dom";


import workLine from '../../img/walk-the-pet.png';
import showLine from '../../img/dog-training.png';


export default function Find_Area(){


{/*className="flaticon-dove"             className="flaticon-happy"*/}
    return(
        <div className="find-area">
        <div className="container custom-container">
            <div className="row">
                <div className="col-12">
                    <form action="#">
                        <div className="find-wrap">
                            <div className="location" style={{visibility: "hidden"}}>
                                <i className="flaticon-location"></i>
                                <input type="text" defaultValue="Enter City, State. or Zip"/>
                            </div>
                            <div className="find-category">
                                <ul>
                                    <li><Link to={"/studs"}><i className="flaticon-dog"></i>Find a Stud</Link></li>
                                    <li><Link to={"/show-line"}><img  src={showLine} style={{width: 45+'px', height: 45+'px'}}/><i></i> Show Line</Link></li>
                                    <li><Link to={"/work-line"}><img  src={workLine} style={{width: 45+'px', height: 45+'px'}}/><i></i> Working Line</Link></li>
                                </ul>
                            </div>
                            <div className="other-find">
                                <div className="dropdown" style={{visibility: "hidden"}}>
                                    <button  className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Find Other Pets
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        
                                        <Link className="dropdown-item" href="shop.html">Need a stud</Link>
                                        <Link className="dropdown-item" href="shop.html">Find Your Cat</Link>
                                        <Link className="dropdown-item" href="shop.html">Find Your Birds</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    );
}
