import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { getPuppies, getStuds } from "../../my-api/myApi";
import Preloader from "../components/preloader";
import ShowStuds from "./show_studs";

export default function StudsShopArea(){

    const { isLoading, isError, data, error } = useQuery("studs", getStuds);

    // Show message to user depending if studs are available or not
    function message(){

        if(isLoading) return <Preloader />
        if(isError || data.data == null){

            return(
                <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-9">
                        <div className="section-title text-center mb-65">
                            <div className="section-icon"><img src={require("../../img/icon/pawprint.png")} alt=""/></div>
                            <h5 className="sub-title">Breeding Services</h5>
                            <h5 className="title">An Error ocurred loading the data.</h5>
                        </div>
                    </div>
                </div>
            </div>

            )
        }

        let total = data.data.filter(stud => stud.attributes.gender == 'M' && stud.attributes.available_for_breeding == true)

        if(total.length > 0) return(
            <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-9">
                    <div className="section-title text-center mb-65">
                        <div className="section-icon"><img src={require("../../img/icon/pawprint.png")} alt=""/></div>
                        <h5 className="sub-title">Breeding Services</h5>
                        <h2 className="title">Available Studs</h2>
                    </div>
                </div>
            </div>


            <ShowStuds studs={data.data}/>

        </div>

        )



        return (
            <div className="section-title text-center mb-65">
                <div className="section-icon"><img src={require("../../img/icon/pawprint.png")} alt=""/></div>
                <h5 className="sub-title">Breeding Services</h5>
                <h2 className="title">No Available Studs</h2>
            </div>
        )

    }


    return(
        <section className="adoption-shop-area">

            {message()}

        </section>
    );
}