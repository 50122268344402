import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import OwlCarousel  from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import { getHomePack } from '../my-api/myApi';
import Preloader from './components/preloader';


import pawprint from "../../src/img/icon/pawprint.png";
import GTEST from "../../src/img/GSTEST.png";
import breed_services_bg from "../../src/img/bg/breed_services_bg.jpg";


import arrow from '../../src/img/icon/arrow.png';


export default function TheCarrousel(){
    const prev = `<img src='${arrow}' />`;
    const options = {
    loop: true,
	margin: 30,
	items: 2,
	autoplay: false,
	autoplayTimeout: 5000,
	autoplaySpeed: 1000,
	navText: [prev, prev],
	nav: true,
	dots: false,
	responsive: {
            0: {
                items: 1,
                center: false,
                nav: false,
            },
            575: {
                items: 1,
                center: false,
                nav: false,
            },
            768: {
                items: 1,
                center: false,
                nav: false,
            },
            992: {
                items: 2,
                center: false,
                nav: false,
            },
            1200: {
                items: 2
            }
        }
    };



    const { isLoading, isError, data, error } = useQuery("getHomePack", getHomePack);

    if(isLoading) return <Preloader />


    if(isError || data.data == null){

        return(
            <section className="breeds-services pt-110 pb-110">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-9">
                        <div className="section-title text-center mb-65">
                            <div className="section-icon"><img src={pawprint} alt=""/></div>
                            <h5 className="sub-title">Service to Breeds</h5>
                            <h2 className="title">Most Popular Dog Breed</h2>
                            <p>We pride ourselves on producing healthy, well-tempered, and genetically sound dogs that make excellent breeding stock for those looking to produce the next generation of exceptional canine companions.</p>
                        </div>
                    </div>
                </div>
                
                <div className="row justify-content-center">
                
                    <div className="col-lg-8">
                        <h1>Error Loading the Data</h1>
                    </div>
                    
                    <div className="col-lg-4 col-md-6">
                        <div className="breed-services-info" style={{backgroundImage: `url(${breed_services_bg})`}}>
                            <h5 className="sub-title">Dog Breeding</h5>
                            <h3 className="title">Meet Our Furry Family</h3>
                            <p>We produce high-quality, healthy puppies, and we're excited to work with you.</p>
                            <Link to={"/contact-us"} className="btn">Contact Us<img src={require("../../src/img/icon/w_pawprint.png")} alt=""/></Link>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="breed-services-shape"><img src={require("../../src/img/images/breed_services_shape01.png")} alt=""/></div>
            <div className="breed-services-shape shape-two"><img src={require("../../src/img/images/breed_services_shape02.png")} alt=""/></div>
        </section>
        )
    }

    


    return(
        <section className="breeds-services pt-110 pb-110">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-9">
                        <div className="section-title text-center mb-65">
                            <div className="section-icon"><img src={pawprint} alt=""/></div>
                            <h5 className="sub-title">Service to Breeds</h5>
                            <h2 className="title">Most Popular Dog Breed</h2>
                            <p>We pride ourselves on producing healthy, well-tempered, and genetically sound dogs that make excellent breeding stock for those looking to produce the next generation of exceptional canine companions.</p>
                        </div>
                    </div>
                </div>
                
                <div className="row justify-content-center">
                
                    <div className="col-lg-8">
                    <OwlCarousel className="breed-services-active owl-carousel" {...options}>
                            {
                                data.data.map(home_pack => {
                                    return(
                                        <div key={home_pack.id} className="breed-services-item">
                                            <div className="thumb">
                                                <img src={(home_pack.attributes.picURL == null) ? GTEST : home_pack.attributes.picURL} alt=""/>
                                            </div>
                                            <div className="content">
                                                <h3 className="title"><Link to={`${home_pack.attributes.line.toLowerCase()}-line/${home_pack.attributes.name}`} state = {{ puppy: home_pack}}>{home_pack.attributes.name}</Link></h3>
                                            </div>
                                        </div>
                                        )
                                    }
                                )
                            }
                        </OwlCarousel>
                    </div>
                    
                    <div className="col-lg-4 col-md-6">
                        <div className="breed-services-info" style={{backgroundImage: `url(${breed_services_bg})`}}>
                            <h5 className="sub-title">Dog Breeding</h5>
                            <h3 className="title">Meet Our Furry Family</h3>
                            <p>We produce high-quality, healthy puppies, and we're excited to work with you.</p>
                            <Link to={"/contact-us"} className="btn">Contact Us<img src={require("../../src/img/icon/w_pawprint.png")} alt=""/></Link>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="breed-services-shape"><img src={require("../../src/img/images/breed_services_shape01.png")} alt=""/></div>
            <div className="breed-services-shape shape-two"><img src={require("../../src/img/images/breed_services_shape02.png")} alt=""/></div>
        </section>

    );

}