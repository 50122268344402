import React from "react";

import TopNavbar from "./components/navbar/top_navbar";
import Navbar from "./components/navbar/navbar";
import NavbarSearch from "./components/navbar/navbar_search";


export default function Header(props){

    return(
        <header>
            <TopNavbar />
            <Navbar homeActive={props.homeActive}/>
            <NavbarSearch />
        </header>

    );
}