import React, { useState, useEffect } from "react";

import './preloader.css';

export default function Preloader(){
    return(

        <div id="preloader">
            <img src={require("../../img/duke-championdog.gif")} alt=""/>
        </div>
    
        
    );
}