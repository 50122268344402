import React from "react";
import store  from '../../store/store';


import Navbar from "../components/navbar/navbar";
import NotSection1 from "../components/not_section_1";
import LinesShopArea from "./lines_shop_area";

import Footer from "../Footer";


export default function WorkLine(props){


    const puppies = store(state => state.work_line_puppies);

    return(
        <React.Fragment>
            <Navbar lineActive="active"/>
            <NotSection1 title="Working Line"/>

            <LinesShopArea line="Working"/>

            <Footer />

        </React.Fragment>
    );
}