import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../components/navbar/navbar";
import NotSection1 from "../components/not_section_1";
import Footer from "../Footer";

import ShowAge from "../components/show_age"



import { useLocation } from "react-router-dom";


export default function ShowSingleAStud() {

    let info = useLocation();

    const stud = info.state.stud;

    return (

        <React.Fragment>

            <Navbar />
            <NotSection1 title={`Studs: ${stud.attributes.name}`}/>

            <section className="breeder-details-area">
            
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="breeder-details-content">
                                <h4 className="title">{`${stud.attributes.name.toUpperCase()}`}</h4>
                             
                                <div className="breeder-details-img">
                                    <img src={stud.attributes.picURL} alt=""/>
                                </div>
                                <h4 className="title">About Bio</h4>
                                <p>{stud.attributes.bio}</p>
                                <div className="breeder-dog-info">
                                    <h5 className="title">Dog Information</h5>
                                    <div className="row">
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Elbows:</h6>
                                                <span>{stud.attributes.elbows}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Age:</h6>
                                                <span>{ShowAge(stud.attributes.birth)}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Color:</h6>
                                                <span>{stud.attributes.color}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Microship:</h6>
                                                <span>{stud.attributes.microship}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Hip:</h6>
                                                <span>{stud.attributes.hips}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Weight:</h6>
                                                <span>{stud.attributes.weight} lbs</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Father:</h6>
                                                <a href={stud.attributes.father_pd_url} target="_blank"><span>{stud.attributes.father_name}</span></a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Mother:</h6>
                                                <a href={stud.attributes.mother_pd_url} target="_blank"><span>{stud.attributes.mother_name}</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <Link to={"/contact-us"} className="btn">Contact Us <img src={require("../../img/icon/w_pawprint.png")} alt=""/></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <aside className="breeder-sidebar">
                                <div className="widget breeder-widget" style={{display: "none"}}>
                                    <div className="breeder-widget-title mb-20">
                                        <h5 className="title">Find Your Pets</h5>
                                    </div>
                                    <form action="#" className="sidebar-find-pets">
                                        <div className="form-grp search-box">
                                            <input type="text" placeholder="Search"/>
                                            <button><i className="fas fa-search"></i></button>
                                        </div>
                                        <div className="form-grp">
                                            <i className="flaticon-location"></i>
                                            <input type="text" placeholder="Location"/>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-grp">
                                                    <i className="flaticon-color-palette"></i>
                                                    <input type="text" placeholder="White"/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-grp">
                                                    <i className="far fa-calendar-alt"></i>
                                                    <input type="text" value="2021"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-grp">
                                            <i className="flaticon-sex"></i>
                                            <select name="name" className="selected">
                                                <option value="">Female</option>
                                                <option value="">Male</option>
                                                <option value="">Adoption</option>
                                                <option value="">Breeder</option>
                                            </select>
                                        </div>
                                        <div className="form-grp">
                                            <i className="fas fa-dollar-sign"></i>
                                            <select name="name" className="selected">
                                                <option value="">Price</option>
                                                <option value="">$100 - $150</option>
                                                <option value="">$150 - $250</option>
                                                <option value="">$250 - $350</option>
                                                <option value="">$350 - $550</option>
                                                <option value="">$550 - $1000</option>
                                            </select>
                                        </div>
                                        <div className="form-grp">
                                            <i className="flaticon-plus-18-movie"></i>
                                            <select name="name" className="selected">
                                                <option value="">Adult :</option>
                                                <option value="">6 Month</option>
                                                <option value="">9 Month</option>
                                                <option value="">1 Year</option>
                                            </select>
                                        </div>
                                        <button className="btn">Find New Pets</button>
                                    </form>
                                </div>
                                <div className="widget sidebar-newsletter" style={{display: "none"}}>
                                    <div className="sn-icon">
                                        <img src={require("../../img/icon/sn_icon.png")} alt=""/>
                                    </div>
                                    <div className="sn-title">
                                        <h4 className="title">Subscribe Newsletter</h4>
                                        <p>Sign-up For Latest News</p>
                                    </div>
                                    <form action="#" className="sn-form">
                                        <input type="text" placeholder="Enter Your Email"/>
                                        <button className="btn">subscribe</button>
                                    </form>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}