import { Link, useLocation } from "react-router-dom";

import './show_gender.css';

export default function ShowLineMales(props){


    const puppies = props.puppies
    const line = props.line;



    return(

        <div className="row justify-content-center">
            {
                puppies.filter(puppy => puppy.attributes.gender == 'M' && puppy.attributes.line == line).map((puppy) => {

                    return (
                        <div className="col-lg-4 col-md-6">
                            <div className="adoption-shop-item">
                                <div className="adoption-shop-thumb">
                                    <img src={puppy.attributes.picURL} alt=""/>
                                    <Link to={`${puppy.attributes.name}`} state = {{ puppy: puppy}} className="btn">View More <img src={require("../../img/icon/w_pawprint.png")} alt=""/></Link>
                                </div>
                                <div className="adoption-shop-content">
                                    <h4 className="title"><Link to={`${puppy.attributes.name}`} state = {{ puppy: puppy}}>{puppy.attributes.name}</Link></h4>
                                    <div className="adoption-meta">
                                        <ul>
                                            <li className="price">Line : <span>{puppy.attributes.line}</span></li>
                                            <li><i className="far fa-calendar-alt"></i> Birth : {puppy.attributes.birth}</li>
                                        </ul>
                                    </div>
                                    <div className="adoption-rating">
                                        <ul>
                                        <li className="price">Father : <a href={puppy.attributes.father_pd_url} target="_blank">{puppy.attributes.father_name}</a></li>
                                            <li className="rating" style={{display: "none"}}>
                                                
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="adoption-rating" style={{paddingTop: "1rem"}}>
                                        <ul>
                                        <li className="price">Mother : <a href={puppy.attributes.mother_pd_url} target="_blank">{puppy.attributes.mother_name}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}