import React from "react";


import Navbar from "../components/navbar/navbar";
import NotSection1 from "../components/not_section_1";
import PuppiesShopArea from "./puppies_shop_area";

import Footer from "../Footer";


export default function AvailablePuppies(){

    return(
        <React.Fragment>
            <Navbar puppiesActive="active"/>
            <NotSection1 title="Available Pupies"/>

            <PuppiesShopArea />

            <Footer />

        </React.Fragment>
    );
}