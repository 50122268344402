import { Link } from "react-router-dom";
import './show_gender.css';
import { useEffect, useState } from "react";


export default function SHowStuds(props){


    const studs = props.studs;
    

    return(
        <div className="row justify-content-center">
            {
                studs.filter(stud => stud.attributes.gender == 'M' && stud.attributes.available_for_breeding == true).map((stud) => {

                    return (
                        
                        <div key={stud.id} className="col-lg-4 col-md-6">
                            <div className="adoption-shop-item">
                                <div className="adoption-shop-thumb">
                                    <img src={stud.attributes.picURL} alt=""/>
                                    <Link to={stud.attributes.name} state = {{ stud: stud}} className="btn">View More <img src={require("../../img/icon/w_pawprint.png")} alt=""/></Link>
                                </div>
                                <div className="adoption-shop-content">
                                    <h4 className="title"><Link to={stud.attributes.microship} state = {{ stud: stud}}>{stud.attributes.name}</Link></h4>
                                    <div className="adoption-meta">
                                        <ul>
                                            <li className="price">Line : <span>{stud.attributes.line}</span></li>
                                            <li><i className="far fa-calendar-alt"></i> Birth : {stud.attributes.birth}</li>
                                        </ul>
                                    </div>
                                    <div className="adoption-rating">
                                        <ul>
                                        <li className="price">Father : <a href={stud.attributes.father_pd_url} target="_blank">{stud.attributes.father_name}</a></li>

                                        </ul>
                                    </div>
                                    <div className="adoption-rating" style={{paddingTop: "1rem"}}>
                                        <ul>
                                        <li className="price">Mother : <a href={stud.attributes.mother_pd_url} target="_blank">{stud.attributes.mother_name}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}