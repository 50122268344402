import React, { useState, setState, useEffect } from "react";
import SwithGender from "../Doglist/showGender/switch_gender";


export default function AdoptionShopArea(props){

    // Declare puppies state
    const puppies  = props.store_puppies;


    

    return(
        <section className="adoption-shop-area">

            <SwithGender puppies={puppies}/>
        </section>
    );
}