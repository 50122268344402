import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { getAdoption } from "../../my-api/myApi";
import Preloader from "../components/preloader";
import ShowAdoptionMales from "./show_adoption_males";
import ShowAdoptionFemales from "./show_adopted_females";

export default function AdoptionShopArea(){
    const [maleCLicked, setMale ] = useState(true);

    const { isLoading, isError, data, error } = useQuery("adoptions", getAdoption);

    if(isLoading) return <Preloader />

    if(isError || data.data == null){

        return(
            <section className="adoption-shop-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-9">
                            <div className="section-title text-center mb-65">
                                <div className="section-icon"><img src={require("../../img/icon/pawprint.png")} alt=""/></div>
                                <h5 className="sub-title">Dogs Waiting For Their Forever Home</h5>
                                <h5 className="title">An Error Ocurred Loading the Data.</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return(
        <section className="adoption-shop-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-9">
                        <div className="section-title text-center mb-65">
                            <div className="section-icon"><img src={require("../../img/icon/pawprint.png")} alt=""/></div>
                            <h5 className="sub-title">Dogs Waiting For Their Forever Home</h5>
                            <h2 className="title">Available Dogs</h2>
                                <div className="navbar-wrap main-menu d-lg-flex" style={{justifyContent: "center"}}>
                                    <ul className="select-buttons navigation">
                                        <li className={(maleCLicked) ? "active" : ""} onClick={() => setMale(!maleCLicked)}><a>Males</a></li>
                                        <li className={(!maleCLicked) ? "active" : ""} onClick={() => setMale(!maleCLicked)}><a>Females</a></li>
                                    </ul>
                                </div>
                        </div>
                    </div>
                </div>
                
                { (maleCLicked) ? <ShowAdoptionMales adopted_dogs={data.data}/> : <ShowAdoptionFemales adopted_dogs={data.data}/>}

            </div>
        </section>
    );
}