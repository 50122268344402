import React, { useState, useEffect } from "react";


import CounterBG from "../../img/bg/counter_bg.jpg";
import Counter from "../components/counter";

import Preloader from "../components/preloader";


import { useQuery } from "react-query";
import { getPuppies } from "../../my-api/myApi";


export default function Counter_Area(){

    const { isLoading, isError, data, error } = useQuery("puppies", getPuppies);


    if(isLoading) return <Preloader/>



    return(
        <section className="counter-area counter-bg" style={{backgroundImage: `url(${CounterBG})`}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8">
                        <div className="counter-title text-center mb-65">
                            <h6 className="sub-title">Why Choose Us?</h6>
                            <h2 className="title">Best Service to Breed Your Loved Dog</h2>
                        </div>
                    </div>
                </div>

                <Counter totalPuppies={(isError || data.data == null) ? 0 : data.data.length}/>
            </div>
        </section>
    );
}