import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, mutation } from 'react-query';
import { sendPostRequest } from "../my-api/myApi";


import Navbar from "./components/navbar/navbar";
import NavbarSearch from "./components/navbar/navbar_search";
import Newsletter from "./components/newsletter";

import { GoogleReCaptchaProvider, GoogleRecaptchaComponent} from 'react-google-recaptcha-v3';



import NotSection1 from "./components/not_section_1";

import Footer from "./Footer";

import './contact.css';



export default function Contact(props){

    const [defaultMessage, setDefaultMessage] = useState("Send Now")
    const [response, setResponse] = useState({})

    const [ data, setData ] = useState({name: '', last_name:'', email:'', phone: 0, message: '', "publishedAt": null})

    const mutation = useMutation(() => {

        return fetch(`${process.env.REACT_APP_MESSAGE_URL}/api/clients`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
            body: JSON.stringify({data:data})
        }).then((responseJSON) => {

            handleMessage(responseJSON)

        }).catch((error) => {
            console.log(error)
          });

    });



    const handleSubmit = (e) => {
        e.preventDefault()
        
        mutation.mutate()
    }

    const handleMessage = (response) => {

        if(response.status != 200) {
            setDefaultMessage("Error Sending Message")

            setTimeout(function(){
                setDefaultMessage("Send Again")
              }, 2000);

        }

        if(response.status == 200){
            setDefaultMessage("Message Sent")

            setTimeout(function(){
                setData({name: '', last_name:'', email:'', phone: 0, message: '', "publishedAt": null})
                setDefaultMessage("Send Now")
              }, 2000);

        }
    }

    return(
        <React.Fragment>
        <header>
            <Navbar contactusActive={props.contactusActive}/>
            <NavbarSearch />
        </header>


    <main>

        <NotSection1 title={props.title}/>
        <section className="contact-area pt-110 pb-110">
            <div className="container">
                <div className="container-inner-wrap">
                    <div className="row justify-content-center justify-content-lg-between">
                        <div className="col-lg-6 col-md-8 order-2 order-lg-0">
                            <div className="contact-title mb-20">
                                <h5 className="sub-title">Contact Us</h5>
                                <h2 className="title">Let's Talk Question<span>.</span></h2>
                            </div>
                            <div className="contact-wrap-content">
                                <p>Take the first step towards finding your perfect furry companion and clear any doubts by reaching out today - your future loyal and loving companion awaits!</p>
                                <form className="contact-form" onSubmit={handleSubmit}>
                                    <div className="form-grp">
                                        <label htmlFor="name">Your Name <span>*</span></label>
                                        <input type="text" id="name" placeholder="Jhon..." value={data.name} onChange={e => setData({ ...data, name: e.target.value})}/>
                                    </div>
                                    <div className="form-grp">
                                        <label htmlFor="last_name">Your Last Name <span>*</span></label>
                                        <input type="text" id="last_name" placeholder="Doe..." value={data.last_name} onChange={e => setData({ ...data, last_name: e.target.value})}/>
                                    </div>
                                    <div className="form-grp">
                                        <label htmlFor="email">Your Email <span>*</span></label>
                                        <input type="text" id="email" placeholder="info.example@.com" value={data.email} onChange={e => setData({ ...data, email: e.target.value})}/>
                                    </div>
                                    <div className="form-grp">
                                        <label htmlFor="phone">Your Phone Number <span>*</span></label>
                                        <input type="number" id="phone" value={(data.phone == 0) ? "305..." : data.phone} onChange={e => setData({ ...data, phone: parseInt(e.target.value)})}/>
                                    </div>
                                    <div className="form-grp">
                                        <label htmlFor="message">Your Message <span>*</span></label>
                                        <textarea name="message" id="message" placeholder="Tell us your thoughts..." value={data.message} onChange={e => setData({ ...data, message: e.target.value})}></textarea>
                                    </div>
                                    {/* <div className="form-grp checkbox-grp">
                                        <input type="checkbox" id="checkbox"/>
                                        <label htmlFor="checkbox">Don’t show your email address</label>
                                    </div> */}

                                    <button type="submit" className="btn rounded-btn">{defaultMessage}</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-8">
                            <div className="contact-info-wrap">
                                <div className="contact-img">
                                    <img src={require("../img/images/contact_img.png")} alt=""/>
                                </div>
                                <div className="contact-info-list">
                                    <ul>
                                        <li>
                                            <div className="icon"><i className="fas fa-map-marker-alt"></i></div>
                                            <div className="content">
                                                <p>18430 SW 264 St, Homestead, Fl 33031 United States</p>
                                            </div>
                                        </li>
                                        <li>
                                            <a href="tel:7862823620">
                                            <div className="icon"><i className="fas fa-phone-alt"></i></div>
                                            <div className="content"><p>(786) 282-3620</p></div>
                                            </a>
                                        </li>
                                        <li>
                                            <div className="icon"><i className="fas fa-envelope-open"></i></div>
                                            <div className="content">
                                                <p>dcaballerogsbreeder@gmail.com</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="contact-social">
                                    <ul>
                                        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="https://instagram.com/caballerogsbreeder?igshid=YmMyMTA2M2Y=" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <Newsletter />

        <Footer />

    </main>

    </React.Fragment>
    );
}