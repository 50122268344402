import React, { useState, useEffect} from "react";

export default function CountEffect(value){


    const [count, setCount] = useState(0);


    useEffect(() => {
        
        const timeoutId = setTimeout(() => setCount(value), 700);

        return () => {
            clearTimeout(timeoutId);
        };}, []);



        return count;

}