
import { useState, useEffect} from "react";
import ShowMales from "../../AvailablePuppies/show_male";
import ShowFemales from "../../AvailablePuppies/show_females";
import {
    useQuery,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from "react-query";


import { getPuppies } from "../../../my-api/myApi";



export default function SwithGender(props){

    const [maleCLicked, setMale ] = useState(true);

    const { isLoading, isError, data, error } = useQuery("puppies", getPuppies);


    function Loading(){
        return <div>Loading</div>
    }

    if(isLoading) return <Loading />


    if(isError || data.data == null){
        return(
            <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-9">
                    <div className="section-title text-center mb-65">
                        <div className="section-icon"><img src={require("../../../img/icon/pawprint.png")} alt=""/></div>
                        <h5 className="sub-title">Meet Our Puppies</h5>
                        <h5 className="title">An Error Ocurred Loading the Data.</h5>
                    </div>
                </div>
            </div>
        </div>
        )
    }


    
    const stored_puppies = props.puppies;


    return(
        <div className="container">
        <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
                <div className="section-title text-center mb-65">
                    <div className="section-icon"><img src={require("../../../img/icon/pawprint.png")} alt=""/></div>
                    <h5 className="sub-title">Meet Our Dogs</h5>
                    <h2 className="title">{"Puppies for Sale"}</h2>
                    <div className="navbar-wrap main-menu d-lg-flex" style={{justifyContent: "center"}}>
                        <ul className="select-buttons navigation">
                            <li className={(maleCLicked) ? "active" : ""} onClick={() => setMale(!maleCLicked)}><a>Males</a></li>
                            <li className={(!maleCLicked) ? "active" : ""} onClick={() => setMale(!maleCLicked)}><a>Females</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        


        { (maleCLicked) ? <ShowMales puppies={data.data}/> : <ShowFemales puppies={data.data}/>}

    </div>
    );
}