import React, { useState, useEffect} from "react";
import Preloader from "./components/preloader";
import { Navigate } from "react-router-dom";


export default function NotFound(){

    const [ load, setLoad ] = useState(false);

    useEffect(() => {
        // simulate a delay to show preloader
        setTimeout(() => {
          setLoad(true);
        }, 2000);
      }, []);


    return(
        <React.Fragment>

            {load ? (<Navigate to="/"/>) :

                <Preloader />

            }
            

        </React.Fragment>
    );


}