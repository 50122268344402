import React from "react";


import Navbar from "../components/navbar/navbar";
import NotSection1 from "../components/not_section_1";
import AdoptionShopArea from "./adoption_shop_area";

import Footer from "../Footer";


export default function Adoption(){

    return(
        <React.Fragment>
            <Navbar/>
            <NotSection1 title="Adoption"/>

            <AdoptionShopArea />
            <Footer />

        </React.Fragment>
    );
}