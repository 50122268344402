import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import OwlCarousel  from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

import { useQuery, useQueries } from "react-query";
import { getPuppies, getStuds } from "../../my-api/myApi";


import Counter from "../components/counter";
import Navbar from "../components/navbar/navbar";
import NotSection1 from "../components/not_section_1";
import Footer from "../Footer";


import arrow from '../../img/icon/arrow.png';
import arrow_bg from '../../img/slider/slider_arrow_shape.png';
import breed_bg from "../../img/bg/breed_services_bg.jpg";


import GTEST from "../../img/GSTEST.png"


import './our_breeder.css';
import Preloader from "../components/preloader";


// Function for next arrow
function PrevArrow(props) {

    const { className, style, onClick } = props;
    return (
        <button type="button" className={className} onClick={onClick}>
            <img src={arrow} />
        </button>
    );
}

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button 
        type="button"
        className={className}
        onClick={onClick}>
            <img src={arrow} />
        </button>
    );
}


export default function OurBreeder(props){

    // Create both queries
    const queries = [
        { queryKey: "studs", queryFn: getStuds },
        { queryKey: "puppies", queryFn: getPuppies },
    ];
    
    const queryResults = useQueries(queries);
    
    const studsQuery = queryResults[0];
    const puppiesQuery = queryResults[1];
    
    // Access each query as follows using the name of the value of the obj
    const { isLoading: isLoadingStuds, isError: isErrorStuds, data: dataStuds, error: errorStuds } = studsQuery;
    const { isLoading: isLoadingPuppies, isError: isErrorPuppies, data: dataPuppies, error: errorPuppies } = puppiesQuery;



    if(isLoadingStuds || isLoadingPuppies) return <Preloader/>


    const settings = {
        loop: true,
        margin: 0,
        items: 1,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplaySpeed: 1000,
        nav: false,
        dots: false
    };

    const options = {
        dots: false,
        infinite: true,
        speed: 1000,
        autoplay: false,
        arrows: true,
        autoplaySpeed: 3000,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    speed: 1000,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    speed: 1000,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    speed: 1000,
                }
            },
        ]
    };


    const hanldeStudError = () => {

        if(isErrorStuds || dataStuds.data == null){
            return (
                <div className="breeder-item">
                    <Link to="/studs">
                        <img src={GTEST} alt=""/>
                    </Link>
                </div>
            )
        }else{
            dataStuds.data.filter(stud => stud.attributes.gender == 'M' && stud.attributes.available_for_breeding == true).map((stud) => {

                return (
                    <div className="breeder-item">
                        <Link to="/studs">
                            <img src={(stud.attributes.picURL == null) ? GTEST : stud.attributes.picURL} alt=""/>
                        </Link>
                    </div>
                )
            })
        }

    }

    const handlePuppyError = () => {
        if(isErrorPuppies || dataPuppies.data == null) {
            return(
                <div className="col-xl-3">
                    <div className="adoption-item">
                        <h1>Error Loading Data</h1>
                    </div>
                </div>

            )
        }
        else {
            dataStuds.data.map((stud) => {

                return(
                    <div className="col-xl-3">
                        <div className="adoption-item">
                            <div className="adopt-thumb">
                                <a href="breeder-details.html"><img src={stud.attributes.picURL} alt=""/></a>
                            </div>
                            <div className="adopt-content">
                                <div className="adopt-date"><i className="far fa-calendar-alt"></i> Birth: {stud.attributes.birth}</div>
                                <h3 className="title"><a href="breeder-details.html">{stud.attributes.line} Line</a></h3>
                                <p>Meet {stud.attributes.name}</p>
                                <Link to="/working-line" className="read-more">Read More <img src={require("../../img/icon/pawprint.png")} alt=""/></Link>
                            </div>
                        </div>
                    </div>

                )
            })
        }
    }





    return(
        <React.Fragment>
            <Navbar ourBreedActive={props.ourBreedActive}/>
            <NotSection1 title={props.title}/>

            <section className="inner-breeder-area breeder-bg">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-4 col-lg-9 col-md-12 col-sm-9">
                            <div className="adoption-content">
                                <h5 className="sub-title">D' Caballero GS Breeder</h5>
                                <h2 className="title">Top-quality <span>German Shepherds</span> for You!</h2>
                                <p>Discover reputable German Shepherd breeder in Miami, FL, offering top-notch, healthy pups with exceptional temperament and lineage.</p>
                                <div className="adoption-list">
                                    <ul>
                                        <li><i className="flaticon-tick"></i> Healthy Champion Bloodlines</li>
                                        <li><i className="flaticon-tick"></i> Dedicated and Experienced Team</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-9 col-md-12 col-sm-9">
                            <div className="breeder-info-wrap">
                                <div className="row no-gutters justify-content-center">
                                    <div className="col-md-6">
                                        <OwlCarousel className="breeder-active owl-carousel" {...settings}>
                                            {
                                                hanldeStudError
                                            }
                                        </OwlCarousel>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="breed-services-info" style={{backgroundImage: `url(${breed_bg})`}}>
                                            <h5 className="sub-title">Dog Breeder</h5>
                                            <h3 className="title">Available for Breed</h3>
                                            <p>Maximize your breeding program with our outstanding studs and their proven lineage.</p>
                                            <Link to="/studs" className="btn">Our Studs <img src={require("../../img/icon/w_pawprint.png")} alt=""/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="counter-area mt-70">
                    <div className="container">
                        <Counter totalPuppies={(isErrorPuppies || dataPuppies.data == null) ? 0 : dataPuppies.data.length}/>
                    </div>
                </div>
                
            </section>

            <section className="adoption-area-two pt-110 pb-110">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-9">
                            <div className="section-title text-center mb-65">
                                <div className="section-icon"><img src="img/icon/pawprint.png" alt=""/></div>
                                <h5 className="sub-title">Meet Our Pack</h5>
                                <h2 className="title">Our Furry Family Greets You</h2>
                                <p>The best overall dog DNA test is Embark Breed &amp; Health Kit (view at Chewy), which provides
                                    you with a breed brwn and information most dogs</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container custom-container">
                        <Slider className="row adopt-active"  {...options}>
                        {
                            handlePuppyError
                        }

                    </Slider>
                </div>
            </section>


            <Footer />
        </React.Fragment>
    );
}