import React from "react";

import axios from "axios";


export async function getPuppies(){

    const response = await fetch(`${process.env.REACT_APP_PUPPIES_URL}`);
    const jsonData = await response.json();

    return jsonData;

}


export async function getHomePack(){

    const response = await fetch(`${process.env.REACT_APP_HOME_PACK_URL}`);
    const jsonData = await response.json();

    return jsonData;

}


export async function getAdoption(){

    const response = await fetch(`${process.env.REACT_APP_ADOPTION_URL}`);
    const jsonData = await response.json();

    return jsonData;

}


export async function getStuds(){

    const response = await fetch(`${process.env.REACT_APP_HOME_PACK_URL}`);
    const jsonData = await response.json();

    return jsonData;

}