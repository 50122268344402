import React from "react";

export default function TopNavbar(){


    return(
        <div className="header-top-area">
        <div className="container custom-container">
            <div className="row align-items-center">
                <div className="col-md-7">
                    <div className="header-top-left">
                        <ul>
                            <li>Call us: 786-282-3620</li>
                            {/*<li><i className="far fa-clock"></i>Opening Hours: 7:00 am - 9:00 pm (Mon - Sun)</li>*/}
                        </ul>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="header-top-right">
                        <ul className="header-top-social">
                            <li className="follow">Follow :</li>
                            <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                            {/*<li><a href="#"><i className="fab fa-twitter"></i></a></li>*/}
                            <li><a href="https://instagram.com/caballerogsbreeder?igshid=YmMyMTA2M2Y=" target="_blank"><i className="fab fa-instagram"></i></a></li>
                            {/*<li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>*/}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
