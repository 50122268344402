import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes,  } from 'react-router-dom';

import Footer from './views/Footer';

import Home from './views/Home';
import Contact from './views/Contact';
import BreederDetails from './views/BreederDetails/breeder_details';
import OurBreeder from './views/OurBReeder/our_breeder';




// Import css
import './css/bootstrap.min.css';
import './css/animate.min.css';
import './css/magnific-popup.css';
import './css/fontawesome-all.min.css';
import './css/nice-select.css';
import './css/flaticon.css';
import './css/odometer.css';
import './css/jquery-ui.css';
import 'slick-carousel/slick/slick.css';
import './css/default.css';
import './css/style.css';
import './css/responsive.css';
import NotFound from './views/NOT_FOUND';
import SinglePuppy from './views/SinglePuppy/single_puppy';
import ShowLine from './views/Doglist/show_line';
import Studs from './views/Studs/studs';
import WorkLine from './views/Doglist/work_line';


import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import AvailablePuppies from './views/AvailablePuppies/available_puppies';
import Adoption from './views/Adoption/adoption';
import ShowSingleAdoption from './views/Adoption/show_single_adoption';
import ShowSingleAStud from './views/Studs/show_single_stud';
import LinesShopArea from './views/Doglist/lines_shop_area';
import SingleLine from './views/Doglist/lines_single';

const queryClient = new QueryClient()



function App() {
  


  return (

    <QueryClientProvider client={queryClient}>
      
      <React.Fragment>
        <BrowserRouter>
          <Routes>
              <Route exact path="/" element={<Home homeActive="active" />}/>
              <Route exact path="/home" element={<Home homeActive="active" />}/>
              <Route path="/contact-us" element={<Contact title="Contact Us" contactusActive="active"/>}/>
              <Route path="/studs" element={<Studs title="Studs" studsActive="active" />}/>
              <Route path="/working-line" element={<WorkLine title="Work Line" lineActive="active" />}/>
              <Route path="/show-line" element={<ShowLine title="Show Line" lineActive="active" />}/>
              <Route path="/breeder-details" element={<BreederDetails title="Breeder Details"/>}/>
              <Route path="/our-breeder" element={<OurBreeder title="Our Breeder" ourBreedActive="active"/>}/>
              <Route path="/available-puppies" element={<AvailablePuppies />}/>
              <Route path="/available-puppies/:id" element={<SinglePuppy />}/>
              <Route path="/adoption" element={<Adoption />}/>
              <Route path="/adoption/:id" element={<ShowSingleAdoption Title="Adoption" />}/>
              <Route path="/studs/:id" element={<ShowSingleAStud title="Studs"/>}/>
              <Route path="/working-line/:id" element={<SingleLine title="Work Line Puppy"/>}/>
              <Route path="/show-line/:id" element={<SingleLine title="Show Line Puppy"/>}/>
              <Route path='*' element={<NotFound/>}/>

          </Routes>
        </BrowserRouter>
      </React.Fragment>

      <ReactQueryDevtools initialIsOpen={false} />
      
    </QueryClientProvider>
  );
}

export default App;
