import React from "react";
import { Link } from "react-router-dom";


import breadcrumb_bg from '../../img/bg/bg-small.jpg';


export default function NotSection1(props){
    return(
        <section className="breadcrumb-area breadcrumb-bg" style={{backgroundImage: `url(${breadcrumb_bg})`}}>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="breadcrumb-content">
                        <h2 className="title">{props.title}</h2>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{props.title}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}