import React from "react";


export default function FAQArea(){


    return(
        <section className="faq-area faq-bg">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="faq-img-wrap">
                        <img src={require("../../img/images/faq_tv.png")} className="img-frame" alt=""/>
                        <img src={require("../../images/noel.jpg")} className="main-img" alt=""/>
                        <a href="https://instagram.com/caballerogsbreeder?igshid=YmMyMTA2M2Y=" className="popup-video"></a>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="faq-wrapper">
                        <div className="section-title mb-35">
                            <h5 className="sub-title">FAQ Question</h5>
                            <h2 className="title">History & Family Adoption</h2>
                        </div>
                        <div className="accordion" id="accordionExample">
                            <div className="card">
                                <div className="card-header" id="headingOne">
                                    <h2 className="mb-0">
                                        <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                            data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Working for dog adoption
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div className="card-body">
                                    Responsible breeder offering a diverse selection of well-cared-for dogs for adoption, ensuring each pup finds a loving forever home.
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <h2 className="mb-0">
                                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                                            data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Competitions & Awards
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div className="card-body">
                                    Esteemed breeder specializing in award-winning dogs with a rich pedigree history, providing exceptional companions with impressive lineage.
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                    <h2 className="mb-0">
                                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                                            data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            The puppies are 3 months old
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div className="card-body">
                                    Experienced breeder offering healthy and well-socialized German Shepherd puppies at 3 months old, ready to become loyal and protective family members.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="faq-shape"><img src={require("../../img/images/faq_shape.png")} alt=""/></div>
    </section>
    );
}