import React from "react";
import { Link } from "react-router-dom";


export default function Adoption_Area(){

    return(
        <section className="adoption-area">
        <div className="container">
            <div className="row align-items-center align-items-xl-end justify-content-center">
                <div className="col-xl-7 col-lg-6 col-md-10 order-0 order-lg-2">
                    <div className="adoption-img" >
                        <img src={require("../../images/ken.jpg")} alt="" />
                    </div>
                </div>
                <div className="col-xl-5 col-lg-6">
                    <div className="adoption-content">
                        <h2 className="title">Working For <br/> Dog <span>Adoption</span> Free, Happy Time</h2>
                        <p>
                            Adopting a dog not only saves a life, but it also brings joy, love, and companionship into your home. <br></br>
                            Dogs are loyal, affectionate, and can even improve your physical and mental health, making them a valuable addition to your family.
                        </p>
                        <Link to={"/adoption"} className="btn">Adoption <img src={require("../../img/icon/w_pawprint.png")} alt=""/></Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="adoption-shape"><img src={require("../../img/images/adoption_shape.png")} alt=""/></div>
    </section>
    );
}