import React, { useEffect, useState } from "react";


import Header from "./Header";
import Section_1 from "./Main/Section_1";
import Find_Area from "./Main/find_area";
import Counter_Area from "./Main/counter_area";
import Adoption_Area from "./Main/adoption_area";
import TheCarrousel from "./Carrousel";
import FAQArea from "./Main/faq_area";
import BrandArea from "./Main/brand_area";
import AdoptionShopArea from "./Main/adoption_shop_area";
// import Testimonial from "./Main/testimonials_area";
// import BlogArea from "./Main/blog_area";
import Newsletter from "./components/newsletter";
import Footer from "./Footer";



export default function Home(props){

  return(
      <React.Fragment>

          <Header homeActive={props.homeActive} />
          <Section_1 />
          <Find_Area />
          <Counter_Area />
          <Adoption_Area />
          <TheCarrousel />
          <FAQArea />
          <BrandArea />
          <AdoptionShopArea/>
          {/* <Testimonial /> */}
          {/* <BlogArea /> */}
          <Newsletter />
          <Footer />
    </React.Fragment>
  );
}