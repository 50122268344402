import React, { useEffect } from "react";
import store  from '../../store/store';


import Navbar from "../components/navbar/navbar";
import NotSection1 from "../components/not_section_1";
import LinesShopArea from "./lines_shop_area";

import Footer from "../Footer";



export default function ShowLine(){

    const puppies = store(state => state.show_line_puppies);


    return(
      
        <React.Fragment>


            <Navbar  lineActive="active"/>
            <NotSection1 title="Show Line"/>

            <LinesShopArea line="Show"/>

            <Footer />

        </React.Fragment>
    );
}