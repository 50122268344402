import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from 'jquery';


import './navbar.css';

import headerShape from '../../../img/bg/header_shape.png';

export default function Navbar(props){
    useEffect(() => {

            //Menu Toggle Btn
            $('.mobile-nav-toggler').on('click', function () {
                console.log("Click visible");
                $('body').addClass('mobile-menu-visible');
            });

            //Menu Toggle Btn
            $('.menu-backdrop, .mobile-menu .close-btn').on('click', function () {
                console.log("Click invisible");
                $('body').removeClass('mobile-menu-visible');
            });


            //Menu Toggle Btn
            $('.menu-outer ul li, .mobile-menu .nav-logo').on('click', function () {
                console.log("Click invisible");
                $('body').removeClass('mobile-menu-visible');
            });
    
    }, []);



    return(

        <div id="sticky-header" className="menu-area">
        <div className="container custom-container">
            <div className="row">
                <div className="col-12">
                    <div className="mobile-nav-toggler"><i className="fas fa-bars"></i></div>
                    <div className="menu-wrap">
                        <nav className="menu-nav show">
                            <div className="logo"><Link to={"/"}><img src={require("../../../img/logo/Logob.png")} alt=""/></Link></div>
                            <div className="navbar-wrap main-menu d-none d-lg-flex">
                                <ul className="navigation">
                                    <li className={props.homeActive}><Link to={"/"}>Home</Link></li>
                                    <li className={props.studsActive}><Link to={"/studs"}>Studs</Link></li>
                                    <li className={`menu-item-has-children ${props.lineActive}`}><Link to={"/working-line"}>Our Pack</Link>
                                        <ul className="submenu">
                                            <li><Link to={"/working-line"}>Working Line</Link></li>
                                            <li><Link to={"/show-line"}>Show Line</Link></li>
                                        </ul>
                                    </li>
                                    <li className={props.puppiesActive}><Link to={"/available-puppies"}>Puppies</Link></li>
                                    <li className={props.ourBreedActive}><Link to={"/our-breeder"}>Breeder</Link></li>
                                    <li className={props.contactusActive}><Link to={"/contact-us"}>contact us</Link></li>
                                    
                                </ul>
                            </div>
                            <div className="header-action d-none d-md-block">
                                <ul>
                                    <li className="header-search" style={{display: "none"}}><a href="#"><i className="flaticon-search"></i></a></li>
                                    <li className="header-shop-cart" style={{display: "none"}}><a href="#"><i className="flaticon-shopping-bag"></i><span>2</span></a>
                                        <ul className="minicart">
                                            <li className="d-flex align-items-start">
                                                <div className="cart-img">
                                                    <a href="#"><img src={require("../../../img/product/cart_p01.jpg")} alt=""/></a>
                                                </div>
                                                <div className="cart-content">
                                                    <h4><a href="#">The King Charles Spaniel</a></h4>
                                                    <div className="cart-price">
                                                        <span className="new">$229.9</span>
                                                        <span><del>$229.9</del></span>
                                                    </div>
                                                </div>
                                                <div className="del-icon">
                                                    <a href="#"><i className="far fa-trash-alt"></i></a>
                                                </div>
                                            </li>
                                            <li className="d-flex align-items-start">
                                                <div className="cart-img">
                                                    <a href="#"><img src={require("../../../img/product/cart_p02.jpg")} alt=""/></a>
                                                </div>
                                                <div className="cart-content">
                                                    <h4><a href="#">The Labrador Retriever</a></h4>
                                                    <div className="cart-price">
                                                        <span className="new">$229.9</span>
                                                        <span><del>$229.9</del></span>
                                                    </div>
                                                </div>
                                                <div className="del-icon">
                                                    <a href="#"><i className="far fa-trash-alt"></i></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="total-price">
                                                    <span className="f-left">Total:</span>
                                                    <span className="f-right">$239.9</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="checkout-link">
                                                    <a href="#">Shopping Cart</a>
                                                    <a className="black-color" href="#">Checkout</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="header-btn"><Link to={"/adoption"} className="btn">Adopt Here <img src={require("../../../img/icon/w_pawprint.png")} alt=""/></Link></li>
                                </ul>
                            </div>
                        </nav>
                    </div>


                    <div className="mobile-menu">
                        <nav className="menu-box">
                            <div className="close-btn"><i className="fas fa-times"></i></div>
                            <div className="nav-logo"><Link to={"/"}><img src={require("../../../img/logo/Logob.png")} alt="" title=""/></Link>
                            </div>

                            <div className="menu-outer">
                                <ul className="navigation">
                                <li className={props.homeActive}><Link to={"/"}>Home</Link></li>
                                    <li className={props.studsActive}><Link to={"/studs"}>Studs</Link></li>
                                    <li className={props.workLineActive}><Link to={"/work-line"}>Working Line</Link></li>
                                    <li className={props.showLineActive}><Link to={"/show-line"}>Show Line</Link></li>
                                    <li className={props.contactusActive}><Link to={"/available-puppies"}>Puppies</Link></li>
                                    <li className={props.ourBreedActive}><Link to={"/our-breeder"}>Breeder</Link></li>
                                    <li className={props.contactusActive}><Link to={"/contact-us"}>contact us</Link></li>
                                </ul>
                            </div>




                            <div className="social-links">
                                <ul className="clearfix">
                                    <li><a href="#"><span className="fab fa-facebook-square"></span></a></li>
                                    <li><a href="https://instagram.com/caballerogsbreeder?igshid=YmMyMTA2M2Y=" target="_blank"><span className="fab fa-instagram"></span></a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>



                    <div className="menu-backdrop"></div>
               

               
                </div>
            </div>
        </div>
        <div className="header-shape" style={{backgroundImage: `url(${headerShape})`}}></div>
    </div>
    );
}