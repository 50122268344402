import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { getHomePack } from "../../my-api/myApi";
import ShowLineMales from "./lines_show_male";
import ShowLineFemales from "./lines_show_female";
import Preloader from "../components/preloader";

export default function LinesShopArea(props){
    const [maleCLicked, setMale ] = useState(true);

    const { isLoading, isError, data, error } = useQuery("homePack", getHomePack);

    function message(){
        if(isLoading) return <Preloader />


        if(isError || data.data == null) return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-9">
                        <div className="section-title text-center mb-65">
                            <div className="section-icon"><img src={require("../../img/icon/pawprint.png")} alt=""/></div>
                            <h5 className="sub-title">Meet Our Family</h5>

                            <h4 className="title">An error ocurred loading the data.</h4>
                        </div>
                    </div>
                </div>
            </div>
        )


        let total_show = data.data.filter(home_pack => home_pack.attributes.line == "Show")
        let total_work = data.data.filter(home_pack => home_pack.attributes.line == "Working")


        if(total_work.length == 0 && props.line == "Working"){
            return(
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-9">
                            <div className="section-title text-center mb-65">
                                <div className="section-icon"><img src={require("../../img/icon/pawprint.png")} alt=""/></div>
                                <h5 className="sub-title">Meet Our Family</h5>
    
                                <h5 className="title">There are no members of Working Line.</h5>
                            </div>
                        </div>
                    </div>
                </div>          
            )
        }

        if(total_show.length == 0 && props.line == "Show"){
            return(
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-9">
                            <div className="section-title text-center mb-65">
                                <div className="section-icon"><img src={require("../../img/icon/pawprint.png")} alt=""/></div>
                                <h5 className="sub-title">Meet Our Family</h5>
    
                                <h5 className="title">There are no members of Show Line.</h5>
                            </div>
                        </div>
                    </div>
                </div>          
            )
        }


        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-9">
                        <div className="section-title text-center mb-65">
                            <div className="section-icon"><img src={require("../../img/icon/pawprint.png")} alt=""/></div>
                            <h5 className="sub-title">Meet Our Family</h5>
                                <div className="navbar-wrap main-menu d-lg-flex" style={{justifyContent: "center"}}>
                                    <ul className="select-buttons navigation">
                                        <li className={(maleCLicked) ? "active" : ""} onClick={() => setMale(!maleCLicked)}><a>Males</a></li>
                                        <li className={(!maleCLicked) ? "active" : ""} onClick={() => setMale(!maleCLicked)}><a>Females</a></li>
                                    </ul>
                                </div>
                        </div>
                    </div>
                </div>
                
                { (maleCLicked) ? <ShowLineMales puppies={data.data} line={props.line}/> : <ShowLineFemales puppies={data.data} line={props.line}/>}

            </div>
        )

    }

    return(
        <section className="adoption-shop-area">

            {message()}

        </section>
    );
}