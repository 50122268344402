import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../components/navbar/navbar";
import NotSection1 from "../components/not_section_1";
import Footer from "../Footer";

import GTEST from "../../img/GSTEST.png"


import ShowAge from "../components/show_age";

import { useLocation } from "react-router-dom";


export default function SinglePuppy() {

    let info = useLocation();

    const puppy = info.state.puppy;


    function Availability() {

        if(puppy.attributes.sold) return <span>Sold</span>;
        if(puppy.attributes.booked) return <span>Booked</span>;

        return <span>Available</span>
    }

    return (

        <React.Fragment>

            <Navbar />
            <NotSection1 title={`Collar Color: ${puppy.attributes.collar_color}`}/>

            <section className="breeder-details-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="breeder-details-content">
                                <h4 className="title">{`${puppy.attributes.collar_color.toUpperCase()} COLLAR`}</h4>
                                <div className="breeder-details-img">
                                    <img src={(puppy.attributes.picURL == null) ? GTEST : puppy.attributes.picURL} alt=""/>
                                </div>
                                <h4 className="title">About Bio</h4>
                                <p>The domestic dog is doiated dendant of the wolf. The dog t is derived from an ancient, extinct wolf, and the modern grey wolf is the dog's nesdarest living relative. The dog was the first species to be dometed, by hunter.</p>
                                <div className="breeder-dog-info">
                                    <h5 className="title">Dog Information</h5>
                                    <div className="row">
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Gender:</h6>
                                                <span>{(puppy.attributes.gender == 'M') ? "Male" : "Female"}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Age:</h6>
                                                <span>{ShowAge(puppy.attributes.birth)}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Color:</h6>
                                                <span>{puppy.attributes.color}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Microship:</h6>
                                                <span>{(puppy.attributes.microship == null ) ? "Not Provided" : puppy.attributes.microship}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Size:</h6>
                                                <span>{(puppy.attributes.size == null ) ? "Not Provided" : puppy.attributes.size}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Father:</h6>
                                                <a href={puppy.attributes.father_pd_url} target="_blank"><span>{(puppy.attributes.father_pd_url == null ) ? "Not Provided" : puppy.attributes.father_pd_url}</span></a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Mother:</h6>
                                                <a href={puppy.attributes.mother_pd_url} target="_blank"><span>{(puppy.attributes.mother_pd_url == null ) ? "Not Provided" : puppy.attributes.mother_pd_url}</span></a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Availability</h6>
                                                <Availability />
                                            </div>
                                        </div>
                                    </div>
                                    <Link to={"/contact-us"} className="btn">Contact Us <img src={require("../../img/icon/w_pawprint.png")} alt=""/></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4" style={{display: "none"}}>
                            <aside className="breeder-sidebar">
                                <div className="widget breeder-widget">
                                    <div className="breeder-widget-title mb-20">
                                        <h5 className="title">Find Your Pets</h5>
                                    </div>
                                    <form action="#" className="sidebar-find-pets">
                                        <div className="form-grp search-box">
                                            <input type="text" placeholder="Search"/>
                                            <button><i className="fas fa-search"></i></button>
                                        </div>
                                        <div className="form-grp">
                                            <i className="flaticon-location"></i>
                                            <input type="text" placeholder="Location"/>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-grp">
                                                    <i className="flaticon-color-palette"></i>
                                                    <input type="text" placeholder="White"/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-grp">
                                                    <i className="far fa-calendar-alt"></i>
                                                    <input type="text" value="2021"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-grp">
                                            <i className="flaticon-sex"></i>
                                            <select name="name" className="selected">
                                                <option value="">Female</option>
                                                <option value="">Male</option>
                                                <option value="">Adoption</option>
                                                <option value="">Breeder</option>
                                            </select>
                                        </div>
                                        <div className="form-grp">
                                            <i className="fas fa-dollar-sign"></i>
                                            <select name="name" className="selected">
                                                <option value="">Price</option>
                                                <option value="">$100 - $150</option>
                                                <option value="">$150 - $250</option>
                                                <option value="">$250 - $350</option>
                                                <option value="">$350 - $550</option>
                                                <option value="">$550 - $1000</option>
                                            </select>
                                        </div>
                                        <div className="form-grp">
                                            <i className="flaticon-plus-18-movie"></i>
                                            <select name="name" className="selected">
                                                <option value="">Adult :</option>
                                                <option value="">6 Month</option>
                                                <option value="">9 Month</option>
                                                <option value="">1 Year</option>
                                            </select>
                                        </div>
                                        <button className="btn">Find New Pets</button>
                                    </form>
                                </div>
                                <div className="widget sidebar-newsletter">
                                    <div className="sn-icon">
                                        <img src={require("../../img/icon/sn_icon.png")} alt=""/>
                                    </div>
                                    <div className="sn-title">
                                        <h4 className="title">Subscribe Newsletter</h4>
                                        <p>Sign-up For Latest News</p>
                                    </div>
                                    <form action="#" className="sn-form">
                                        <input type="text" placeholder="Enter Your Email"/>
                                        <button className="btn">subscribe</button>
                                    </form>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}