export default function ShowAge(date_y_m_d){


    const splittedDate = date_y_m_d.split("-")


    const date = new Date()

    let actual_year = date.getFullYear();
    let actual_month = date.getMonth() + 1;

    const year = parseInt(splittedDate[0])
    const month = parseInt(splittedDate[1])
    const day = parseInt(splittedDate[2])



    if( month < actual_month){

        const months = actual_month - month
        const years = actual_year - year

        if(years == 0){
            return months + " months"
        }else {
            return years + " years" + ", " + months + " months"
        }
    } 
    else if(month > actual_month) {

        const months = 12 - (month - actual_month)
        const years = actual_year - year



        return years + " years " + ", " + months + " months" 
    }
    else {

        const years = actual_year - year

        return years + " years"
    }



}