import React, { useState, useEffect } from "react";

import Odometer from 'react-odometerjs';

import CountEffect from "../../hooks/count_effect";

import './counter.css';


export default function Counter(props){

    
    
    return(
        <div className="row justify-content-center">

            <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="counter-item">
                    <h2 className="count"><Odometer value={CountEffect(25)}/>+</h2>
                    <p>Years Of History</p>
                </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="counter-item">
                    <h2 className="count"><Odometer value={CountEffect(20)}/>+</h2>
                    <p>Titular Dogs</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="counter-item">
                    <h2 className="count"><Odometer value={CountEffect(props.totalPuppies)}/></h2>
                    <p>Puppies Available</p>
                </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="counter-item">
                    <h2 className="count">
                        <div className="adoption-rating">
                            <ul>
                                <li className="rating">
                                    
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </li>
                            </ul>
                        </div>
                    </h2>
                    <p>Client Satisfaction</p>
                </div>
            </div>
        </div>
    );
}