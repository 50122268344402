import { Link } from "react-router-dom";
import './show_gender.css';


export default function ShowAdoptionFemales(props){


    const adopted_dogs = props.adopted_dogs;


    return(
        <div className="row justify-content-center">
            {
                adopted_dogs.filter(adopted_dog => adopted_dog.attributes.gender == 'F').map((adopted_dog) => {

                    return (
                        <div className="col-lg-4 col-md-6">
                            <div className="adoption-shop-item">
                                <div className="adoption-shop-thumb">
                                    <img src={adopted_dog.attributes.picURL} alt=""/>
                                    <Link to={`${adopted_dog.id}`} state = {{ adopted_dog: adopted_dog}} className="btn">View More <img src={require("../../img/icon/w_pawprint.png")} alt=""/></Link>
                                </div>
                                <div className="adoption-shop-content">
                                    <h4 className="title"><Link to={`${adopted_dog.id}`} state = {{ adopted_dog: adopted_dog}}>{adopted_dog.attributes.name}</Link></h4>
                                    <div className="adoption-meta">
                                        <ul>
                                            <li className="price">Line : <span>{adopted_dog.attributes.line}</span></li>
                                            <li><i className="far fa-calendar-alt"></i> Birth : {adopted_dog.attributes.birth}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}