import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../components/navbar/navbar";
import NotSection1 from "../components/not_section_1";
import Footer from "../Footer";

import ShowAge from "../components/show_age";



import { useLocation } from "react-router-dom";


export default function SingleLine() {

    let info = useLocation();

    const puppy = info.state.puppy;

    function Availability() {

        if(puppy.attributes.sold) return <span>Sold</span>;
        if(puppy.attributes.booked) return <span>Booked</span>;

        return <span>Available</span>
    }




    function showLine(){

        const line = puppy.attributes.line


        if(line == "Working"){

            return(
                <p>Working line dogs are dogs that have been selectively bred for specific tasks such as herding, guarding, hunting, or performing tasks such as search and rescue, police work, or military service. These dogs are bred for their ability to perform physical tasks, their endurance, intelligence, and temperament. 
                    Working line dogs often have a strong drive to perform the tasks they were bred for and require a lot of physical and mental stimulation to be happy and healthy. They are often highly trainable and have a strong desire to please their owners. 
                    Some examples of working line dog breeds include German Shepherds, Belgian Malinois, Doberman Pinschers, Labrador Retrievers, Golden Retrievers, Border Collies, and many others. 
                    It's important to note that not all dogs of these breeds will have the drive or temperament to be successful working dogs, and that proper training and socialization are essential for any dog, regardless of breed or background. 
                    The dog was the first species to be domesticated, by hunter–gateiherers. These will include and the modern grey wolf is the dog's.</p>)
        }

        return(
            <p>Show line dogs, also known as conformation line dogs, are specifically bred and selected for their adherence to breed standards and suitability for dog shows and conformation events. These dogs possess the physical attributes, structure, and movement that align with the breed standard set by kennel clubs. 
                Show line dogs often have well-defined features, such as a symmetrical body, balanced proportions, proper angulation, and distinctive breed-specific characteristics. They have impeccable coats, vibrant colors, and ideal markings. Their temperament is typically calm, confident, and well-behaved, making them comfortable in show ring environments. 
                Show line dogs undergo extensive training and conditioning to develop their showmanship skills, including gaiting, stacking, and standing still for evaluation by judges. Their purpose is to compete and showcase their breed's qualities, which may include temperament, structure, movement, and overall appearance.
        </p>)


    }

    return (

        <React.Fragment>

            <Navbar />
            <NotSection1 title={puppy.attributes.name}/>

            <section className="breeder-details-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="breeder-details-content">
                                <h4 className="title">{`${puppy.attributes.line.toUpperCase()} LINE`}</h4>
                                {showLine()}
                                <div className="breeder-details-img">
                                    <img src={puppy.attributes.picURL} alt=""/>
                                </div>
                                <h4 className="title">About Bio</h4>
                                <p>{puppy.attributes.bio}</p>
                                <div className="breeder-dog-info">
                                    <h5 className="title">Dog Information</h5>
                                    <div className="row">
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Gender:</h6>
                                                <span>{(puppy.attributes.gender == 'M') ? "Male" : "Female"}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Age:</h6>
                                                <span>{ShowAge(puppy.attributes.birth)}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Color:</h6>
                                                <span>{puppy.attributes.color}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Microship:</h6>
                                                <span>{puppy.attributes.microship}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Size:</h6>
                                                <span>{puppy.attributes.size}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Father:</h6>
                                                <a href={puppy.attributes.father_pd_url} target="_blank"><span>{puppy.attributes.father_name}</span></a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Mother:</h6>
                                                <a href={puppy.attributes.mother_pd_url} target="_blank"><span>{puppy.attributes.mother_name}</span></a>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-4 col-6">
                                            <div className="breeder-info-item">
                                                <h6>Line</h6>
                                                <span>{puppy.attributes.line}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <Link to={"/contact-us"} className="btn">Contact Us <img src={require("../../img/icon/w_pawprint.png")} alt=""/></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4" style={{display: "none"}}>
                            <aside className="breeder-sidebar">
                                <div className="widget breeder-widget">
                                    <div className="breeder-widget-title mb-20">
                                        <h5 className="title">Find Your Pets</h5>
                                    </div>
                                    <form action="#" className="sidebar-find-pets">
                                        <div className="form-grp search-box">
                                            <input type="text" placeholder="Search"/>
                                            <button><i className="fas fa-search"></i></button>
                                        </div>
                                        <div className="form-grp">
                                            <i className="flaticon-location"></i>
                                            <input type="text" placeholder="Location"/>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-grp">
                                                    <i className="flaticon-color-palette"></i>
                                                    <input type="text" placeholder="White"/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-grp">
                                                    <i className="far fa-calendar-alt"></i>
                                                    <input type="text" value="2021"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-grp">
                                            <i className="flaticon-sex"></i>
                                            <select name="name" className="selected">
                                                <option value="">Female</option>
                                                <option value="">Male</option>
                                                <option value="">Adoption</option>
                                                <option value="">Breeder</option>
                                            </select>
                                        </div>
                                        <div className="form-grp">
                                            <i className="fas fa-dollar-sign"></i>
                                            <select name="name" className="selected">
                                                <option value="">Price</option>
                                                <option value="">$100 - $150</option>
                                                <option value="">$150 - $250</option>
                                                <option value="">$250 - $350</option>
                                                <option value="">$350 - $550</option>
                                                <option value="">$550 - $1000</option>
                                            </select>
                                        </div>
                                        <div className="form-grp">
                                            <i className="flaticon-plus-18-movie"></i>
                                            <select name="name" className="selected">
                                                <option value="">Adult :</option>
                                                <option value="">6 Month</option>
                                                <option value="">9 Month</option>
                                                <option value="">1 Year</option>
                                            </select>
                                        </div>
                                        <button className="btn">Find New Pets</button>
                                    </form>
                                </div>
                                <div className="widget sidebar-newsletter">
                                    <div className="sn-icon">
                                        <img src={require("../../img/icon/sn_icon.png")} alt=""/>
                                    </div>
                                    <div className="sn-title">
                                        <h4 className="title">Subscribe Newsletter</h4>
                                        <p>Sign-up For Latest News</p>
                                    </div>
                                    <form action="#" className="sn-form">
                                        <input type="text" placeholder="Enter Your Email"/>
                                        <button className="btn">subscribe</button>
                                    </form>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}