import React from "react";


import Navbar from "../components/navbar/navbar";
import NotSection1 from "../components/not_section_1";

import Footer from "../Footer";
import StudsShopArea from "./studs_shop_area";


export default function Studs(props){

    return(
        <React.Fragment>
            <Navbar studsActive="active"/>
            <NotSection1 title="Studs"/>


            <StudsShopArea />
            <Footer />

        </React.Fragment>
    );
}